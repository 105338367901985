import React, {useEffect, useState} from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper, FormControlLabel, Checkbox, FormControl, Select, MenuItem,
} from "@material-ui/core";
import { startOfMonth, endOfMonth, format } from "date-fns";

const barHeight = 15;
const orderBarColor = "#4fb0c2";
const itemBarColor = "#3786f2";

const BorderRight = <div style={{
    position: "absolute",
    right: 0,  // ✅ 讓邊框固定在最右側
    top: 0,
    height: "100%",
    width: "1px",  // ✅ 設定邊框寬度
    borderRight: "1px dashed #ccc",  // ✅ 設定虛線邊框
}} />;
// 生成所有涉及的月份
const generateMonthsFromData = (startMonth: string, endMonth: string): string[] => {
    const start = startOfMonth(new Date(startMonth));
    const end = endOfMonth(new Date(endMonth));

    const months: string[] = [];
    let current = start;

    while (current <= end) {
        months.push(format(current, "yyyy-MM"));
        current = new Date(current.setMonth(current.getMonth() + 1));
    }

    return months;
};


// 計算每個月的預算
const calculateMonthlyBudget = (
    startDate: string,
    endDate: string,
    budget: number,
    filterStart: string,
    filterEnd: string
): Record<string, number> => {
    const monthlyBudget: Record<string, number> = {};
    const itemStartDate = new Date(Date.UTC(
        new Date(startDate).getUTCFullYear(),
        new Date(startDate).getUTCMonth(),
        new Date(startDate).getUTCDate(),
        0, 0, 0, 0
    ));
    const itemEndDate = new Date(Date.UTC(
        new Date(endDate).getUTCFullYear(),
        new Date(endDate).getUTCMonth(),
        new Date(endDate).getUTCDate(),
        23, 59, 59, 999
    ));

    // 轉換篩選區間為 UTC
    const filterStartDate = new Date(Date.UTC(
        new Date(filterStart).getUTCFullYear(),
        new Date(filterStart).getUTCMonth(),
        1, // 設定為該月的第一天
        0, 0, 0, 0
    ));
    const filterEndDate = new Date(Date.UTC(
        new Date(filterEnd).getUTCFullYear(),
        new Date(filterEnd).getUTCMonth(),
        new Date(endOfMonth(new Date(filterEnd))).getUTCDate(), // 設定為該月最後一天
        23, 59, 59, 999
    ));

    // 限制 `item` 的開始與結束日期在 `startMonth ~ endMonth` 內
    const adjustedStartDate = itemStartDate < filterStartDate ? filterStartDate : itemStartDate;
    const adjustedEndDate = itemEndDate > filterEndDate ? filterEndDate : itemEndDate;

    // 總天數（整個 `item` 的走期天數）
    const totalDaysInItem =
        Math.floor((itemEndDate.getTime() - itemStartDate.getTime()) / (1000 * 60 * 60 * 24)) + 1;

    // **如果完全不在篩選區間內，則不計算**
    if (adjustedStartDate > adjustedEndDate) return {};

    let current = new Date(adjustedStartDate);
    const end = new Date(adjustedEndDate);

    while (current <= end) {
        const monthKey = format(current, "yyyy-MM");
        const monthStart = new Date(Date.UTC(current.getUTCFullYear(), current.getUTCMonth(), 1, 0, 0, 0, 0));
        const monthEnd = new Date(Date.UTC(current.getUTCFullYear(), current.getUTCMonth(), new Date(endOfMonth(current)).getUTCDate(), 23, 59, 59, 999));

        // **確保 barStart/barEnd 限制在 `startMonth ~ endMonth` 內**
        const barStart = adjustedStartDate > monthStart ? adjustedStartDate : monthStart;
        const barEnd = adjustedEndDate < monthEnd ? adjustedEndDate : monthEnd;

        const activeDays = Math.floor((barEnd.getTime() - barStart.getTime()) / (1000 * 60 * 60 * 24)) + 1;

        // **依據總走期計算該月份應佔的金額**
        if (activeDays > 0) {
            monthlyBudget[monthKey] = (budget / totalDaysInItem) * activeDays;
        }

        // ✅ **正確遞增 `current`，避免影響原變數**
        current = new Date(Date.UTC(current.getUTCFullYear(), current.getUTCMonth() + 1, 1, 0, 0, 0, 0));
    }

    return monthlyBudget;
};


const calculateFilteredBudget = (
    startDate: string,
    endDate: string,
    budget: number,
    filterStart: string,
    filterEnd: string
): number => {
    const itemStartDate = new Date(startDate);
    const itemEndDate = new Date(endDate);

    // **確保 `filterStartDate` 是該月的第一天，`filterEndDate` 是該月的最後一天**
    const filterStartDate = new Date(Date.UTC(
        new Date(filterStart).getUTCFullYear(),
        new Date(filterStart).getUTCMonth(),
        1,  // **設定為該月的第一天**
        0, 0, 0, 0
    ));

    const filterEndDate = new Date(Date.UTC(
        new Date(filterEnd).getUTCFullYear(),
        new Date(filterEnd).getUTCMonth(),
        new Date(endOfMonth(new Date(filterEnd))).getUTCDate(), // **設定為該月最後一天**
        23, 59, 59, 999
    ));

    // **確保 `itemStartDate` 和 `itemEndDate` 也轉為 UTC**
    const adjustedStartDate = new Date(Date.UTC(
        itemStartDate.getUTCFullYear(),
        itemStartDate.getUTCMonth(),
        itemStartDate.getUTCDate(),
        0, 0, 0, 0
    ));

    const adjustedEndDate = new Date(Date.UTC(
        itemEndDate.getUTCFullYear(),
        itemEndDate.getUTCMonth(),
        itemEndDate.getUTCDate(),
        0, 0, 0, 0
    ));

    // console.log(`修正後 itemStartDate (UTC): ${adjustedStartDate}`);
    // console.log(`修正後 itemEndDate (UTC): ${adjustedEndDate}`);
    // console.log(`修正後 filterStartDate (UTC): ${filterStartDate}`);
    // console.log(`修正後 filterEndDate (UTC): ${filterEndDate}`);

    // 限制 `processedItem` 的開始/結束時間在 `startMonth ~ endMonth` 內
    const finalStartDate = adjustedStartDate < filterStartDate ? filterStartDate : adjustedStartDate;
    const finalEndDate = adjustedEndDate > filterEndDate ? filterEndDate : adjustedEndDate;

    // **如果完全不在篩選區間內，則不計算**
    if (finalStartDate > finalEndDate) return 0;

    // ✅ **使用 UTC 計算總天數**
    const totalDaysInItem = Math.floor((adjustedEndDate.getTime() - adjustedStartDate.getTime()) / (1000 * 60 * 60 * 24)) + 1;

    // ✅ **使用 UTC 計算篩選區間內的有效天數**
    const activeDays = Math.floor((finalEndDate.getTime() - finalStartDate.getTime()) / (1000 * 60 * 60 * 24)) + 1;

    // console.log(`修正後總走期天數 (UTC): ${totalDaysInItem}`);
    // console.log(`修正後篩選區間內的有效天數 (UTC): ${activeDays}`);

    // **計算篩選區間的預算**
    return (budget / totalDaysInItem) * activeDays;
};

// 渲染柱狀圖
const renderBar = (
    startDate: string,
    endDate: string,
    filterStart: string,
    filterEnd: string,
    monthStart: Date,
    monthEnd: Date,
    barColor: string,
    totalBudget: number
) => {
    const periodStart = new Date(startDate);
    const periodEnd = new Date(endDate);

    const filterStartDate = new Date(Date.UTC(
        new Date(filterStart).getUTCFullYear(),
        new Date(filterStart).getUTCMonth(),
        1,  // **設定為該月的第一天**
        0, 0, 0, 0
    ));

    const filterEndDate = endOfMonth(new Date(filterEnd));

    // 限制 `barStart` 和 `barEnd` 在 `monthStart` ~ `monthEnd` 內
    const barStart = periodStart > monthStart ? periodStart : monthStart;
    const barEnd = periodEnd < monthEnd ? periodEnd : monthEnd;

    // 如果不在顯示範圍內，則不顯示
    if (barStart > barEnd) return null;

    // ✅ 使用 UTC 計算天數
    const totalDaysInMonth =
        (monthEnd.getTime() - monthStart.getTime()) / (1000 * 60 * 60 * 24) + 1;
    const activeDays =
        (barEnd.getTime() - barStart.getTime()) / (1000 * 60 * 60 * 24) + 1;

    // 計算 `marginLeft` 和 `widthPercentage`
    const marginLeft =
        ((barStart.getTime() - monthStart.getTime()) / (1000 * 60 * 60 * 24)) /
        totalDaysInMonth *
        100;
    const widthPercentage = (activeDays / totalDaysInMonth) * 100;
    // console.log(`barEnd: ${barEnd.getMonth()+1}月, monthEnd: ${monthEnd.getMonth()+1}月, filterEndDate: ${filterEndDate.getMonth()+1}月`);
    return (
        <div style={{ position: "relative", height: `${barHeight}px`, width: "100%" }}>
            {/* 柱狀圖 */}
            <div
                style={{
                    height: `${barHeight}px`,
                    backgroundColor: barColor,
                    width: `${widthPercentage}%`,
                    marginLeft: `${marginLeft}%`,
                    position: "absolute",
                }}
                title={Math.round(totalBudget).toLocaleString()}
            ></div>

            {/* 顯示數值（僅在最後一個月顯示） */}
            {(barEnd.getMonth() === periodEnd.getMonth() || barEnd.getMonth() === filterEndDate.getMonth()) && (
                <div
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: `${marginLeft + widthPercentage + 2}%`,
                        transform: "translateY(-50%)",
                        whiteSpace: "nowrap",
                        fontSize: "12px",
                        color: "#333",
                    }}
                >
                    ${Math.round(totalBudget).toLocaleString()}

                </div>
            )}
        </div>
    );
};


// 主組件
const CsProjectTable: React.FC<{ filteredData: Array<any>; startMonth: string; endMonth: string }> = ({ filteredData, startMonth, endMonth }) => {
    const months = generateMonthsFromData(startMonth, endMonth);

    const [expandedOrders, setExpandedOrders] = useState<Set<string>>(
        new Set(filteredData.map((order) => order.id))
    );

    // 新增狀態來控制欄位顯示
    const [visibleColumns, setVisibleColumns] = useState({
        project: true,
        customer: true,
        product: true,
        participant: true,
    });
    const [selectedMetric, setSelectedMetric] = useState<"profit" | "revenue">("profit"); // 預設為 "毛利"

    // 新增函式來切換勾選狀態
    const toggleColumn = (column: keyof typeof visibleColumns) => {
        setVisibleColumns(prev => ({ ...prev, [column]: !prev[column] }));
    };

    useEffect(() => {
        setExpandedOrders(new Set(filteredData.map((order) => order.id)));
    }, [filteredData]);

    const toggleExpand = (orderId: string) => {
        setExpandedOrders((prev) => {
            const newSet = new Set(prev);
            if (newSet.has(orderId)) {
                newSet.delete(orderId);
            } else {
                newSet.add(orderId);
            }
            return newSet;
        });
    };

    const getColumnLeft = (column: keyof typeof visibleColumns) => {
        let left = 0;
        if (column === "customer" && visibleColumns.project) left += 200;
        if (column === "product") {
            if (visibleColumns.project) left += 200;
            if (visibleColumns.customer) left += 150;
        }
        if (column === "participant") {
            if (visibleColumns.project) left += 200;
            if (visibleColumns.customer) left += 150;
            if (visibleColumns.product) left += 150;
        }
        return `${left}px`;
    };
    const calculateTotalMonthlyBudget = (
        filteredData: Array<any>,
        startMonth: string,
        endMonth: string,
    ): Record<string, number> => {
        const months = generateMonthsFromData(startMonth, endMonth);

        return months.reduce<Record<string, number>>((acc, month) => {
            acc[month] = 0;
            filteredData.forEach((order) => {
                order.processedItems.forEach((item: any) => {
                    const monthlyBudget = calculateMonthlyBudget(
                        item.startDate,
                        item.endDate,
                        selectedMetric === "profit" ? item.totalAmount + item.serviceFee - item.budget : item.totalAmount + item.serviceFee,
                        startMonth, // ✅ 傳入篩選的 `startMonth`
                        endMonth    // ✅ 傳入篩選的 `endMonth`
                    );
                    acc[month] += monthlyBudget[month] || 0;
                });
            });
            return acc;
        }, {});
    };
    const totalMonthlyBudget = calculateTotalMonthlyBudget(filteredData, startMonth, endMonth);

    return (
        <TableContainer component={Paper} style={{ overflowX: "auto", height: "100%",  // 限制高度，讓 TableBody 滾動
            overflowY: "auto" }} >
            <div style={{ padding: "10px", position: "sticky", left: 0, display:'flex', alignItems: 'center' }}>
                {/*<FormControlLabel control={<Checkbox checked={visibleColumns.project} onChange={() => toggleColumn("project")} />} label="專案" />*/}
                <FormControlLabel control={<Checkbox checked={visibleColumns.customer} onChange={() => toggleColumn("customer")} />} label="客戶名稱" />
                <FormControlLabel control={<Checkbox checked={visibleColumns.product} onChange={() => toggleColumn("product")} />} label="產品名稱" />
                <FormControlLabel control={<Checkbox checked={visibleColumns.participant} onChange={() => toggleColumn("participant")} />} label="參與人" />
                {/* Material-UI Select 選單 */}
                指標：
                <FormControl size="small" style={{ minWidth: 120 }}>
                    <Select
                        value={selectedMetric}
                        onChange={(e) => setSelectedMetric(e.target.value as "profit" | "revenue")}
                    >
                        <MenuItem value="profit">毛利</MenuItem>
                        <MenuItem value="revenue">收入</MenuItem>
                    </Select>
                </FormControl>
            </div>


            <Table style={{
                borderCollapse: "collapse",
                tableLayout: "fixed",
                width: "100%"  }}>
                <TableHead style={{
                    position: "sticky",
                    top: 0,
                    zIndex: 11,  // 讓表頭優先顯示在最上層
                    backgroundColor: "#4A5A69"
                }}>
                    <TableRow style={{ backgroundColor: "#4A5A69" }}>
                        {/* 固定專案欄位 */}
                        {visibleColumns.project && <TableCell style={{
                            width: "200px",
                            color: "white",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            position: "sticky",  // ❄️ 固定
                            left: 0,  // ❄️ 凍結在最左邊
                            background: "#4A5A69", // 避免透明顯示
                            zIndex: 10,
                        }}>
                            {BorderRight}
                            專案
                        </TableCell>}

                        {/* 固定客戶名稱 */}
                        {visibleColumns.customer && <TableCell style={{
                            width: "150px",
                            color: "white",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            position: "sticky",
                            left: getColumnLeft("customer"),
                            background: "#4A5A69",
                            zIndex: 10
                        }}>
                            {BorderRight}
                            客戶名稱
                        </TableCell>}

                        {/* 固定產品名稱 */}
                        {visibleColumns.product && <TableCell style={{
                            width: "150px",
                            color: "white",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            position: "sticky",
                            left: getColumnLeft("product"),
                            background: "#4A5A69",
                            zIndex: 10
                        }}>
                            {BorderRight}
                            產品名稱
                        </TableCell>}

                        {/* 固定參與人 */}
                        {visibleColumns.participant && <TableCell style={{
                            width: "150px",
                            color: "white",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            position: "sticky",
                            left: getColumnLeft("participant"),
                            background: "#4A5A69",
                            zIndex: 10,
                        }}>
                            {BorderRight}
                            參與人
                        </TableCell>}

                        {/* 其他月份欄位 */}
                        {months.map((month) => (
                            <TableCell
                                key={month}
                                style={{
                                    width: "90px",
                                    textAlign: "center",
                                    color: "white",
                                    borderRight: "1px dashed #ccc",
                                }}
                            >
                                {`${format(new Date(month), "MMM")} '${format(new Date(month), "yy")}`}
                            </TableCell>
                        ))}
                        <TableCell
                            style={{
                                flexGrow: 1,
                                textAlign: "center",
                                color: "white",
                                borderRight: "1px dashed #ccc",
                            }}
                        />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filteredData.map((order) => {
                        const orderStartDate = order.processedItems.reduce(
                            (minDate: string, item: any) =>
                                !minDate || new Date(item.startDate) < new Date(minDate) ? item.startDate : minDate,
                            ""
                        );
                        const orderEndDate = order.processedItems.reduce(
                            (maxDate: string, item: any) =>
                                !maxDate || new Date(item.endDate) > new Date(maxDate) ? item.endDate : maxDate,
                            ""
                        );
                        const orderTotalBudget = order.processedItems.reduce(
                            (sum: number, item: any) =>
                            {
                                // const monthStart = startOfMonth(new Date(month));
                                // const monthEnd = endOfMonth(new Date(month));
                                //
                                // // ✅ 計算篩選區間的總預算
                                const filteredBudget = calculateFilteredBudget(
                                    item.startDate,
                                    item.endDate,
                                    selectedMetric === "profit" ? item.totalAmount + item.serviceFee - item.budget : item.totalAmount + item.serviceFee,
                                    startMonth, // ✅ 傳入篩選的 `startMonth`
                                    endMonth    // ✅ 傳入篩選的 `endMonth`
                                );
                                return sum + filteredBudget;
                            },
                            0
                        );

                        const isExpanded = expandedOrders.has(order.id);

                        return (
                            <React.Fragment key={order.id}>
                                <TableRow>
                                    {visibleColumns.project && <TableCell
                                        style={{
                                            width: "200px",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            position: "sticky",  // ❄️ 固定
                                            left: 0,  // ❄️ 凍結在最左邊
                                            zIndex: 10,
                                            background: "white",
                                        }}
                                        onClick={() => toggleExpand(order.id)}
                                    >
                                        <span
                                            style={{
                                                display: "inline-block",
                                                marginRight: "5px",
                                                transform: isExpanded ? "rotate(0)" : "rotate(-90deg)",
                                                transition: "transform 0.2s",
                                            }}
                                        >
                                            ▼
                                        </span>
                                        {BorderRight}
                                        <a href={`/app/order/${order.id}`} target="_blank">
                                            {order.name}
                                        </a>
                                    </TableCell>}
                                    {visibleColumns.customer && <TableCell style={{
                                        width: "150px",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        position: "sticky",
                                        left: getColumnLeft("customer"),
                                        zIndex: 10,
                                        background: "white",
                                    }}>
                                        {BorderRight}
                                        {order.customer?.name || "無"}
                                    </TableCell>}
                                    {visibleColumns.product && <TableCell style={{
                                        width: "150px",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        position: "sticky",
                                        left: getColumnLeft("product"),
                                        zIndex: 10,
                                        background: "white",
                                    }}>
                                        {BorderRight}
                                        -
                                    </TableCell>}
                                    {visibleColumns.participant && <TableCell style={{
                                        width: "150px",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        position: "sticky",
                                        left: getColumnLeft("participant"),
                                        zIndex: 10,
                                        background: "white",
                                    }}>
                                        {BorderRight}
                                        {order.assignedSalesUser?.name || "無"}
                                    </TableCell>}
                                    {months.map((month) => {
                                        const monthStart = startOfMonth(new Date(month));
                                        const monthEnd = endOfMonth(new Date(month));

                                        return (
                                            <TableCell
                                                key={month}
                                                style={{ textAlign: "center", padding: 0, borderRight: "1px dashed #ccc" }}
                                            >
                                                {renderBar(
                                                    orderStartDate,
                                                    orderEndDate,
                                                    startMonth,  // ✅ 加入篩選開始月份
                                                    endMonth,    // ✅ 加入篩選結束月份
                                                    monthStart,
                                                    monthEnd,
                                                    orderBarColor,
                                                    orderTotalBudget
                                                )}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                                {isExpanded &&
                                order.processedItems.map((item: any) => (
                                    <TableRow key={item.id}>
                                        {visibleColumns.project && <TableCell style={{
                                            width: "200px",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            position: "sticky",
                                            left: 0,
                                            zIndex: 10,
                                            background: "white",
                                        }}>
                                            {BorderRight}

                                            <a href={`/app/orderItem/${item.id}`} target="_blank">
                                                &nbsp;&nbsp;{item.id}
                                            </a>
                                        </TableCell>}
                                        {visibleColumns.customer && <TableCell style={{
                                            width: "150px",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            position: "sticky",
                                            left: getColumnLeft("customer"),
                                            zIndex: 10,
                                            background: "white",
                                        }}>
                                            {BorderRight}
                                            {order.customer?.name || "無"}
                                        </TableCell>}
                                        {visibleColumns.product && <TableCell style={{
                                            width: "150px",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            position: "sticky",
                                            left: getColumnLeft("product"),
                                            zIndex: 10,
                                            background: "white",
                                        }}>
                                            {BorderRight}
                                            {item.product?.name || "無"}
                                        </TableCell>}
                                        {visibleColumns.participant && <TableCell style={{
                                            width: "150px",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            position: "sticky",
                                            left: getColumnLeft("participant"),
                                            background: "white",
                                            zIndex: 10 }}>
                                            {BorderRight}
                                            {item.assignee?.name || "無"}
                                            {item.supporter?.name ? `, ${item.supporter.name}` : ""}
                                        </TableCell>}
                                        {months.map((month) => {
                                            const monthStart = startOfMonth(new Date(month));
                                            const monthEnd = endOfMonth(new Date(month));

                                            // ✅ 計算篩選區間的總預算
                                            const filteredBudget = calculateFilteredBudget(
                                                item.startDate,
                                                item.endDate,
                                                selectedMetric === "profit" ? item.totalAmount + item.serviceFee - item.budget : item.totalAmount + item.serviceFee,
                                                startMonth, // ✅ 傳入篩選的 `startMonth`
                                                endMonth    // ✅ 傳入篩選的 `endMonth`
                                            );

                                            return (
                                                <TableCell
                                                    key={month}
                                                    style={{ textAlign: "center", padding: 0, borderRight: "1px dashed #ccc" }}
                                                >
                                                    {renderBar(
                                                        item.startDate,
                                                        item.endDate,
                                                        startMonth,  // ✅ 加入篩選開始月份
                                                        endMonth,    // ✅ 加入篩選結束月份
                                                        monthStart,
                                                        monthEnd,
                                                        itemBarColor,
                                                        filteredBudget,
                                                    )}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                ))}
                            </React.Fragment>
                        );
                    })}
                    {/* 總計列 */}
                    <TableRow>
                        <TableCell
                            colSpan={Object.values(visibleColumns).filter(Boolean).length}
                            style={{
                                fontWeight: "bold",
                                textAlign: "center",
                                position: "sticky",  // ❄️ 固定
                                left: 0,  // ❄️ 凍結在最左邊
                                zIndex: 10,
                                background: "white",
                            }}
                        >
                            {BorderRight}
                            總計
                        </TableCell>
                        {months.map((month) => (
                            <TableCell
                                key={month}
                                style={{
                                    textAlign: "center",
                                    fontWeight: "bold",
                                    borderRight: "1px dashed #ccc",
                                    padding: 0,
                                }}
                            >
                                {Math.round(totalMonthlyBudget[month]).toLocaleString()}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default CsProjectTable;
