import React, {useEffect, useState} from "react";
import {
    GoogleAdsAccount,
    GoogleAdsCampaign,
    useCreateGoogleAdsTemplateMutation,
    useGetAdsAccountsQuery, useUpdateGoogleAdsTemplateMutation
} from "../../../generated/graphql";
import {
    Box,
    Button,
    Card,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    Grid, IconButton,
    Switch,
    TextField,
    Typography
} from "@material-ui/core";
import {ToggleButton} from "@material-ui/lab";
import {styled} from "@material-ui/styles";
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import CloseIcon from "@material-ui/icons/Close";
import CampaignSelect from "./CampaignSelect";
import AccountSelect from "./AccountSelect";
import NotificationPopup from "../../../common/NotificationPopup";
import CreateMarkupButton from "../markupSetting/CreateMarkupButton";
import {ReportList} from "./ReportList";
import {useUser} from "../../../Auth";
import {omit} from "lodash";

export const AdTypes = [
    { key: "search", label: "搜尋" },
    { key: "display", label: "多媒體" },
    { key: "shopping", label: "購物" },
    { key: "video", label: "影音" },
    { key: "performance_max", label: "最高成效" },
    { key: "demand_gen", label: "需求開發" },
    { key: "app", label: "應用程式" },
];
export type AccountCampaign = {
    accountId: string;
    accountName: string;
    campaignId?: string;
    campaignName?: string;
}

export const FieldMap = {
    customer: "帳戶",
    campaign: "廣告活動",
    ad_group: "廣告群組",
    ad_group_ad: "廣告",
    keyword: "關鍵字",
    gender: "性別",
    age_range: "年齡層",
    device: "裝置",
    ad_schedule: "時段",
    label: "標籤",
    conversion_action: "轉換動作",
    all_conversion_action: "所有轉換動作",
    audience: "目標對象",
    location: "指定地區",
    geographic: "地理位置",
    user_location: "使用者地區",
    asset: '額外資訊',

    total: "總覽",
    daily: "每日",
    daily_total: "每日總計",

    clicks: "點擊",
    impressions: "曝光",
    ctr: "點閱率",
    average_cpc: "平均單次點擊出價",
    cost_micros: "花費",
    interactions: "互動",
    interaction_rate: "互動率",
    top_impression_percentage: "曝光率(頂端)",
    absolute_top_impression_percentage: "曝光率(絕對頂端)",
    engagements: "參與",
    engagement_rate: "參與度",
    average_cpe: "平均單次參與出價",
    average_cpm: "平均千次曝光出價",

    conversions: "轉換",
    cost_per_conversion: "單次轉換費用",
    conversions_from_interactions_rate: "轉換率",
    conversions_value: "總轉換價值",
    value_per_conversion: "單次轉換價值",
    roas: "轉換價值/費用",
    all_conversions: "所有轉換",
    all_conversions_value: "所有轉換價值",
    all_conversions_from_interactions_rate: "所有轉換率",
    cost_per_all_conversions: "單次所有轉換費用",
    view_through_conversions: "瀏覽後轉換",
    new_customer_lifetime_value: "新顧客",

    search_impression_share: "搜尋曝光比重",
    search_top_impression_share: "搜尋頂端曝光比重",
    search_absolute_top_impression_share: "搜尋絕對頂端曝光比重",

    video_views:"觀看數",
    video_view_rate:"收視率",
    average_cpv:"平均單次收視出價",
    video_quartile_p25_rate:"影片播放進度 25%",
    video_quartile_p50_rate:"影片播放進度 50%",
    video_quartile_p75_rate:"影片播放進度 75%",
    video_quartile_p100_rate:"影片播放進度 100%",

    // engagement_rate: "互動工作階段百分比",
    average_page_views: "事件/工作階段",
    average_time_on_site: "每個工作階段的平均參與時間(秒)",

    'no_match':"(無比對)",
    'no_match-total':"總覽(無比對)",
    'no_match-daily':"每日(無比對)",
    'search_term': "搜尋字詞",
    'customer-daily':"帳戶(每日)",
    'campaign-daily':"廣告活動每日",
    'ad_group-daily':"廣告群組每日",
    ad_group_ad_label:"廣告標籤",
    ad_group_ad_keyword:"廣告關鍵字",
    campaign_district:"廣告活動(縣/市)",
    campaign_city:"廣告活動(城市)",
    campaign_region:"廣告活動(指定地區)",


    "county": '縣/市',
    "city": '城市',
    "region": '指定地區',
}



export const formatField = (field) => {
    if(field in FieldMap) {
        return FieldMap[field];
    }
    return field;
}

export const MyToggleButton = styled(ToggleButton)({
    "&.Mui-selected, &.Mui-selected:hover": {
        color: "white",
        backgroundColor: '#C81F4D'
    }
});
function MyDraggableGrid({items, setItems}) {

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const newItems = Array.from(items);
        const [removed] = newItems.splice(result.source.index, 1);
        newItems.splice(result.destination.index, 0, removed);

        setItems(newItems);
    };
    const handleDelete = (itemToDelete) => {
        setItems((items) => items.filter((item) => item !== itemToDelete));
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" direction="horizontal">
                {(provided) => (
                    <Grid container spacing={1} {...provided.droppableProps} ref={provided.innerRef}>
                        {items.map((item, index) => (
                            <Draggable key={item} draggableId={item} index={index}>
                                {(provided) => (
                                    <Grid item ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                        <Chip
                                            label={item.split('-').map((item)=>formatField(item)).join('-')}
                                            onDelete={() => handleDelete(item)}
                                            deleteIcon={<CloseIcon />}
                                            variant="outlined"
                                        />
                                    </Grid>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </Grid>
                )}
            </Droppable>
        </DragDropContext>
    );
}
export default ({template}: any) =>
{
    const [open, setOpen] = useState(false);
    const [createTemplate] = useCreateGoogleAdsTemplateMutation();
    const [updateTemplate] = useUpdateGoogleAdsTemplateMutation();
    const [name, setName] = useState<string>(template?.name);

    const [selectedAdTypes, setSelectedAdTypes] = React.useState<string[]>(template?.adTypes ?? []);
    const toggleOption = (optionKey) => {
        setSelectedAdTypes((currentKeys) =>
            currentKeys.includes(optionKey)
                ? currentKeys.filter((key) => key !== optionKey)
                : [...currentKeys, optionKey]
        );
    };

    const [reportNames, setReportNames] = useState<string[]>(template?.reportNames ?? [])


    const [traffic, setTraffic] = useState(false);
    const [conversion, setConversion] = useState(false);
    const [impression, setImpression] = useState(false);
    const [ga, setGa] = useState(false);
    const [videoField, setVideoField] = useState(false);
    const [interaction, setInteraction] = useState(false);

    const [removeUnexposed, setRemoveUnexposed] = useState<boolean>(template?.removeUnexposed ?? true);

    const [metricsFields, setMetricsFields] = useState<string[]>(template?.metricsFields ?? [])

    const [selectedAccountList, setSelectedAccountList] = useState<GoogleAdsAccount[]>([]);
    const [selectedCampaignList, setSelectedCampaignList] = useState<GoogleAdsCampaign[]>([]);

    const [selectedAccountCampaignList, setSelectedAccountCampaignList] = useState<AccountCampaign[]>(template?.accountCampaigns ?? []);
    const user = useUser();

    const setDefault = () => {
        if (template) {
            setName(template.name || '');
            setSelectedAdTypes(template.adTypes ?? []);
            setReportNames(template.reportNames ?? []);
            setTraffic(false); // 根據模板初始化流量數據
            setConversion(false); // 根據模板初始化轉換數據
            setImpression(false); // 根據模板初始化曝光數據
            setGa(false); // 根據模板初始化GA數據
            setVideoField(false); // 根據模板初始化影音數據
            setRemoveUnexposed(template.removeUnexposed ?? true);
            setMetricsFields(template.metricsFields ?? []);
            setSelectedAccountCampaignList(template.accountCampaigns ?? []);
        } else {
            // 如果是新增模式，重置所有狀態
            setName('');
            setSelectedAdTypes([]);
            setReportNames([]);
            setTraffic(false);
            setConversion(false);
            setImpression(false);
            setGa(false);
            setVideoField(false);
            setRemoveUnexposed(true);
            setMetricsFields([]);
            setSelectedAccountCampaignList([]);
        }
    };

    const onSelectAccount = (account: GoogleAdsAccount) => {
        // Check if the account already exists in the list
        const isExisting = selectedAccountCampaignList.some(item => item.accountId === account.id);
        if (!isExisting) {
            setSelectedAccountCampaignList(prevList => [...prevList, { accountId: account.id, accountName: account.name }]);
        }
    }
    const onSelectCampaign = (campaign: { accountId: string, id: string, name: string }) => {
        // Check if the campaign already exists in the list
        const isExisting = selectedAccountCampaignList.some(item => item.campaignId === campaign.id);
        if (!isExisting) {
            setSelectedAccountCampaignList(prevList => [...prevList, { accountId: campaign.accountId, accountName: '', campaignId: campaign.id, campaignName: campaign.name }]);
        }
    }

    const onCreate = async () => {
        if (!name) {
            NotificationPopup.error(`請輸入報表名稱`);
        }
        else if (selectedAccountCampaignList.length==0) {
            NotificationPopup.error(`請選擇廣告帳戶或廣告活動`);
        }
        else if (reportNames.length==0) {
            NotificationPopup.error(`請選擇報表類型`);
        }
        else if (metricsFields.length==0) {
            NotificationPopup.error(`請選擇數據類型`);
        }
        else {
            const dto = {
                name: name,
                adTypes: selectedAdTypes,
                reportNames: reportNames,
                removeUnexposed: removeUnexposed,
                metricsFields: metricsFields,
                accountCampaigns: selectedAccountCampaignList,
                assigneeId: user?.id,
            };
            if (template == null) {
                await createTemplate({
                    variables: {
                        googleAdsTemplateInput: dto,
                    },
                    refetchQueries: ["getGoogleAdsTemplates"],
                });
            }
            else {
                await updateTemplate({
                    variables: {
                        id: template.id,
                        googleAdsTemplateInput: omit(dto,['assigneeId']),
                    },
                    refetchQueries: ["getGoogleAdsTemplates"],
                });
            }
            setOpen(false);
        }
    }
    const handleDeleteItem = (indexToDelete: number) => {
        setSelectedAccountCampaignList(prevList =>
            prevList.filter((_, index) => index !== indexToDelete)
        );
    };

    return (
        <div>
            <Dialog maxWidth={"lg"} open={open} fullWidth={true}>
                <DialogTitle style={{ backgroundColor: "#DDD" }}>
                    <Typography variant="h4" align="center">
                        建立模板
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} direction='column'>
                        <Grid item>
                            <TextField
                                fullWidth={true}
                                label={"報表名稱"}
                                value={name}
                                onChange={({target})=>{
                                    setName(target.value);
                                }}
                                variant="outlined"/>
                        </Grid>
                        <Grid item container spacing={2}>
                            <Grid item>
                                <AccountSelect onSelectAccount={onSelectAccount}/>
                            </Grid>
                            <Grid item>
                                <CampaignSelect onSelectCampaign={onSelectCampaign}/>
                            </Grid>
                            <Grid item>
                                <CreateMarkupButton/>
                            </Grid>
                        </Grid>
                        <Grid item container spacing={1} alignItems="center">
                            {selectedAccountCampaignList.map((item, index) => (
                                <Grid item key={index}>
                                    <Card>
                                        <Box margin={1} display="flex" alignItems="center" justifyContent="space-between">
                                            <span>{item.accountId}-{item.accountName} {item.campaignId ? `/${item.campaignId}-${item.campaignName}` : ''}</span>
                                            <IconButton onClick={() => handleDeleteItem(index)} size="small">
                                                <CloseIcon />
                                            </IconButton>
                                        </Box>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>

                        <Grid item>
                            廣告類型 (可複選)
                        </Grid>
                        <Grid container item spacing={1}>
                            {AdTypes.map(({ key, label }) => (
                                <Grid item key={key}>
                                    <MyToggleButton
                                        value="check"
                                        selected={selectedAdTypes.includes(key)}
                                        onChange={() => toggleOption(key)}
                                    >
                                        {label}
                                    </MyToggleButton>
                                </Grid>
                            ))}
                        </Grid>

                        <Grid item>
                            報表類型 (可複選)
                        </Grid>
                        <Grid item>
                            <Card>
                                <Box minHeight={40}>
                                    <MyDraggableGrid items={reportNames} setItems={setReportNames}/>
                                </Box>
                            </Card>
                        </Grid>
                        <Grid item container spacing={1}>
                            {
                                ReportList.map((report)=>{
                                    return <Grid item>

                                        <Card>
                                            <Box margin={1}>
                                                <div>
                                                    {report.title}
                                                </div>
                                                {
                                                    report.fields.map((field)=>{
                                                        return <div><MyToggleButton
                                                            selected={reportNames.includes(`${report.name}-${field}`)}
                                                            value="check"
                                                            style={{
                                                                width: 100,
                                                            }}
                                                            onChange={()=>{
                                                                const fieldName = `${report.name}-${field}`;
                                                                setReportNames((currentReportNames) => {
                                                                    // 檢查 item.name 是否已存在於 reportNames 中
                                                                    const index = currentReportNames.indexOf(fieldName);
                                                                    if (index === -1) {
                                                                        // 如果不存在，則加入到 reportNames 陣列
                                                                        return [...currentReportNames, fieldName];
                                                                    } else {
                                                                        // 如果存在，則創建一個新的陣列並移除那個值
                                                                        return currentReportNames.filter((name) => name !== fieldName);
                                                                    }
                                                                });
                                                            }}
                                                        >
                                                            {formatField(field)}
                                                        </MyToggleButton></div>
                                                    })
                                                }
                                            </Box>
                                        </Card>
                                    </Grid>
                                })
                            }
                        </Grid>
                        <Grid item>
                            數據類型 (可複選)
                        </Grid>
                        <Grid container spacing={1}>
                            {[
                                {
                                    value: traffic,
                                    setValue: setTraffic,
                                    label: "基本",
                                },
                                {
                                    value: conversion,
                                    setValue: setConversion,
                                    label: "轉換",
                                },
                                {
                                    value: impression,
                                    setValue: setImpression,
                                    label: "曝光比重",
                                },
                                {
                                    value: videoField,
                                    setValue: setVideoField,
                                    label: "影音",
                                },
                                {
                                    value: ga,
                                    setValue: setGa,
                                    label: "GA4",
                                },
                                {
                                    value: interaction,
                                    setValue: setInteraction,
                                    label: "互動參與",
                                },
                            ].map((item)=>{
                                return <Grid item>
                                    <MyToggleButton
                                        value="check"
                                        selected={item.value}
                                        onChange={() => {
                                            item.setValue(!item.value);
                                        }}

                                    >
                                        {item.label}
                                    </MyToggleButton>
                                </Grid>
                            })}
                        </Grid>
                        <Grid item>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Switch checked={removeUnexposed} onChange={()=>setRemoveUnexposed(!removeUnexposed)} />}
                                    label="移除所有未曝光資料"
                                />
                            </FormGroup>
                        </Grid>
                        <Grid item>
                            <Card>
                                <Box minHeight={40}>
                                    <MyDraggableGrid items={metricsFields} setItems={setMetricsFields}/>
                                </Box>
                            </Card>
                        </Grid>
                        <Grid item container spacing={1}>
                            {
                                [
                                    {
                                        title: "基本",
                                        value: traffic,
                                        fields:[
                                            "clicks",
                                            "impressions",
                                            "ctr",
                                            "average_cpc",
                                            "cost_micros",
                                            "average_cpm",
                                        ]
                                    },
                                    {
                                        title: "轉換",
                                        value: conversion,
                                        fields:[
                                            "conversions",
                                            "cost_per_conversion",
                                            "conversions_from_interactions_rate",
                                            "conversions_value",
                                            "value_per_conversion",
                                            "roas",
                                            "all_conversions",
                                            "all_conversions_value",
                                            "all_conversions_from_interactions_rate",
                                            "cost_per_all_conversions",
                                            "view_through_conversions",
                                            "new_customer_lifetime_value",
                                        ]
                                    },
                                    {
                                        title: "曝光比重",
                                        value: impression,
                                        fields:[
                                            "search_impression_share",
                                            "search_top_impression_share",
                                            "search_absolute_top_impression_share",
                                            "top_impression_percentage",
                                            "absolute_top_impression_percentage",
                                        ]
                                    },
                                    {
                                        title: "影音",
                                        value: videoField,
                                        fields:[
                                            "video_views",
                                            "video_view_rate",
                                            "average_cpv",
                                            "video_quartile_p25_rate",
                                            "video_quartile_p50_rate",
                                            "video_quartile_p75_rate",
                                            "video_quartile_p100_rate",
                                        ]
                                    },
                                    {
                                        title: "GA4",
                                        value: ga,
                                        fields:[
                                            "average_page_views",
                                            "average_time_on_site",
                                        ]
                                    },
                                    {
                                        title: "互動參與",
                                        value: interaction,
                                        fields:[
                                            "interactions",
                                            "interaction_rate",
                                            "engagements",
                                            "engagement_rate",
                                            "average_cpe",
                                        ]
                                    },
                                ].map((column)=>{
                                    return <Grid item>

                                        <Card>
                                            {column.value && <Box margin={1}>
                                                <div>
                                                    {column.title}
                                                </div>
                                                {
                                                    column.fields.map((field)=>{
                                                        return <div><MyToggleButton
                                                            selected={metricsFields.includes(field)}
                                                            value="check"
                                                            style={{
                                                                width: 200,
                                                            }}
                                                            onChange={()=>{
                                                                const fieldName = field;
                                                                setMetricsFields((currentReportNames) => {
                                                                    // 檢查 item.name 是否已存在於 reportNames 中
                                                                    const index = currentReportNames.indexOf(fieldName);
                                                                    if (index === -1) {
                                                                        // 如果不存在，則加入到 reportNames 陣列
                                                                        return [...currentReportNames, fieldName];
                                                                    } else {
                                                                        // 如果存在，則創建一個新的陣列並移除那個值
                                                                        return currentReportNames.filter((name) => name !== fieldName);
                                                                    }
                                                                });
                                                            }}
                                                        >
                                                            {formatField(field)}
                                                        </MyToggleButton></div>
                                                    })
                                                }
                                            </Box>}
                                        </Card>
                                    </Grid>
                                })
                            }
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant={"contained"}
                        color={"secondary"}
                        onClick={() => {
                            setOpen(false);
                            setDefault();
                        }}
                    >
                        取消
                    </Button>
                    <Button
                        variant={"contained"}
                        color={"secondary"}
                        onClick={async () => {
                            await onCreate();
                        }}
                    >
                        儲存
                    </Button>
                </DialogActions>
            </Dialog>

            <Button
                variant="contained"
                color="primary"
                onClick={() => setOpen(true)}
            >
                {template ? '編輯' : '新增Google Ads報表設定'}
            </Button>
        </div>
    )
}
