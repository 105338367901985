import React, {useEffect, useState} from "react";
import {Link as RouterLink, useLocation} from "react-router-dom";
import PropTypes from "prop-types";
import {
    Avatar,
    Box,
    Button,
    Divider,
    Drawer,
    Hidden,
    Typography,
    makeStyles,
    IconButton,
} from "@material-ui/core";
import {
    AlertCircle as AlertCircleIcon,
    BarChart as BarChartIcon, FilePlus, FolderPlus,
    Lock as LockIcon,
    Settings as SettingsIcon,
    ShoppingBag as ShoppingBagIcon,
    User as UserIcon,
    UserPlus as UserPlusIcon,
    Users as UsersIcon,
    Clipboard, FileText, PlusSquare, Pocket, DollarSign, List,
    Box as BoxIcon, Download, Menu, Edit2, TrendingUp, Calendar,
} from "react-feather";
import NavItem from "./NavItem";
import {
    useGetCurrentUserQuery,
    useGetNotificationListQuery,
    Comparator,
    OrderStatus,
    InvoiceStatus,
    RoleIdEnum,
    useGetPurchaseOrderNotificationQuery,
    PurchaseOrderStatusEnum,
    useGetUpgradesBriefQuery,
    UpgradeStatus,
} from "../../../generated/graphql";
import DefaultQueryHandler from "../../../common/DefaultQueryHandler";
import preval from "preval.macro";
import {getFilterQueryVariables} from "../../../views/purchaseOrder/PurchaseOrderView";
import {hasRole} from "../../../common/Util";
import {useUser} from "../../../Auth";
import Logo from "../../../components/Logo";
import {reduce} from "lodash";

const user = {
    avatar: "/static/images/avatars/avatar_6.png",
    jobTitle: "Senior Developer",
    name: "Katarina Smith",
};

const useStyles = makeStyles(() => ({
    avatar: {
        cursor: "pointer",
        width: 64,
        height: 64,
    },
}));

const items = [
    {
        href: "/app/dashboard",
        icon: BarChartIcon,
        title: "儀表板",
    },
    {
        href: "/app/admin",
        icon: UserIcon,
        title: "帳號管理",
        roles: [RoleIdEnum.Admin, RoleIdEnum.AmManager, RoleIdEnum.AeManager, RoleIdEnum.PmManager],
    },
    {
        href: "/app/order",
        icon: FilePlus,
        title: "委刊單",
        roles: [RoleIdEnum.Admin, RoleIdEnum.Am, RoleIdEnum.Ae, RoleIdEnum.AmManager, RoleIdEnum.AeManager, RoleIdEnum.Finance, RoleIdEnum.Op, RoleIdEnum.PmManager],
    },
    {
        href: "/app/orderItem",
        icon: FolderPlus,
        title: "委刊細項",
        roles: [RoleIdEnum.Admin, RoleIdEnum.AmManager, RoleIdEnum.AeManager, RoleIdEnum.Gm, RoleIdEnum.Op, RoleIdEnum.PmManager],
    },
    {
        href: "/app/customer",
        icon: UserIcon,
        title: "客戶",
        roles: [RoleIdEnum.Admin, RoleIdEnum.Am, RoleIdEnum.Ae, RoleIdEnum.AmManager, RoleIdEnum.AeManager, RoleIdEnum.Finance, RoleIdEnum.Op, RoleIdEnum.PmManager],
    },
    {
        href: "/app/upgrade",
        icon: TrendingUp,
        title: "信用升等",
        roles: [RoleIdEnum.Admin, RoleIdEnum.Am, RoleIdEnum.Ae, RoleIdEnum.AmManager, RoleIdEnum.AeManager, RoleIdEnum.Finance, RoleIdEnum.Op, RoleIdEnum.PmManager],
    },
    {
        href: "/app/product",
        icon: BoxIcon,
        title: "產品",
        roles: [RoleIdEnum.Admin, RoleIdEnum.AmManager, RoleIdEnum.AeManager, RoleIdEnum.Finance, RoleIdEnum.PmManager],
    },
    {
        href: "/app/supplier",
        icon: Clipboard,
        title: "供應商",
        roles: [RoleIdEnum.Admin, RoleIdEnum.Finance],
    },
    {
        href: "/app/invoice",
        icon: FileText,
        title: "發票",
        roles: [RoleIdEnum.Admin, RoleIdEnum.Finance, RoleIdEnum.Op],
    },
    {
        href: "/app/deposit",
        icon: List,
        title: "發稿",
        roles: [RoleIdEnum.Admin, RoleIdEnum.Finance, RoleIdEnum.Op],
    },
    {
        href: "/app/purchaseOrder",
        icon: PlusSquare,
        title: "付款單",
        roles: [RoleIdEnum.Admin, RoleIdEnum.Finance, RoleIdEnum.AeManager, RoleIdEnum.AmManager, RoleIdEnum.Gm, RoleIdEnum.PmManager],
    },
    {
        href: "/app/receivedPayment",
        icon: Pocket,
        title: "收款單",
        roles: [RoleIdEnum.Admin, RoleIdEnum.Finance, RoleIdEnum.AeManager, RoleIdEnum.AmManager, RoleIdEnum.Gm, RoleIdEnum.PmManager],
    },
    {
        href: "report/order-item",
        icon: DollarSign,
        title: "財務報表",
        roles: [RoleIdEnum.Admin, RoleIdEnum.Finance],
    },
    {
        href: "/app/industryReport",
        icon: Download,
        title: "產業報表",
        roles: [RoleIdEnum.Admin, RoleIdEnum.Finance, RoleIdEnum.Gm, RoleIdEnum.Op],
    },
    {
        href: "/app/template",
        icon: FileText,
        title: "報表模板",
        roles: [RoleIdEnum.Admin, RoleIdEnum.AmManager, RoleIdEnum.Am],
    },
    {
        href: "/app/record",
        icon: List,
        title: "報表紀錄",
        roles: [RoleIdEnum.Admin, RoleIdEnum.AmManager, RoleIdEnum.Am],
    },
    {
        href: "/app/timeline",
        icon: Calendar,
        title: "時程表",
        roles: [RoleIdEnum.Admin, RoleIdEnum.AmManager, RoleIdEnum.Am, RoleIdEnum.AeManager, RoleIdEnum.Ae, RoleIdEnum.PmManager, RoleIdEnum.Pm],
    },
    {
        href: "/app/log",
        icon: Edit2,
        title: "操作記錄",
        roles: [RoleIdEnum.Admin],
    },
];

export const RoutesList = items.map((item)=>item.href);

const NavBar = () => {
    const classes = useStyles();
    const location = useLocation();
    const user = useUser();
    const {loading, data, error} = useGetUpgradesBriefQuery();
    const [openDrawer, setOpenDrawer] = useState(true);
    const notificationListQuery = useGetNotificationListQuery({
        variables: {
            ordersFiltering: [
                {
                    comparator: Comparator.Equals,
                    key: "status",
                    value: OrderStatus.Changed,
                },
            ],
            invoicesFiltering: [
                {
                    comparator: Comparator.Equals,
                    key: "status",
                    value: InvoiceStatus.Changed,
                },
            ],
        },
    });

    const managerReviewNotificationListQuery = useGetPurchaseOrderNotificationQuery(
        getFilterQueryVariables(PurchaseOrderStatusEnum.ManagerReview)
    );
    const gmReviewNotificationListQuery = useGetPurchaseOrderNotificationQuery(
        getFilterQueryVariables(PurchaseOrderStatusEnum.GmReview)
    );
    const financeReviewNotificationListQuery = useGetPurchaseOrderNotificationQuery(
        getFilterQueryVariables(PurchaseOrderStatusEnum.FinanceReview)
    );
    let purchaseOrderNotifyCount = 0;
    let upgradeNotifyCount = 0;
    if (user) {
        if (hasRole(user, [RoleIdEnum.AmManager, RoleIdEnum.AeManager])) {
            purchaseOrderNotifyCount += managerReviewNotificationListQuery.data?.purchaseOrders.total;
            upgradeNotifyCount += reduce(data?.upgradeList, (acc, upgrade)=>acc + (upgrade.status==UpgradeStatus.ManagerReview ? 1 : 0), 0);
        }
        if (hasRole(user, [RoleIdEnum.Gm])) {
            purchaseOrderNotifyCount += gmReviewNotificationListQuery.data?.purchaseOrders.total;
            upgradeNotifyCount += reduce(data?.upgradeList, (acc, upgrade)=>acc + (upgrade.status==UpgradeStatus.GmReview ? 1 : 0), 0);
        }
        if (hasRole(user, [RoleIdEnum.Finance])) {
            purchaseOrderNotifyCount += financeReviewNotificationListQuery.data?.purchaseOrders.total;
            upgradeNotifyCount += reduce(data?.upgradeList, (acc, upgrade)=>acc + (upgrade.status==UpgradeStatus.FinanceReview ? 1 : 0), 0);
        }
    }

    let formatedItems = items.map((item) => {
        item.invisible = true;
        if (!notificationListQuery.loading) {
            if (item.href == "/app/order")
                item.invisible = notificationListQuery.data?.orders.total == 0;
            if (item.href == "/app/invoice")
                item.invisible = notificationListQuery.data?.invoices.total == 0;
            if (item.href == "/app/purchaseOrder")
                item.invisible = purchaseOrderNotifyCount == 0;
            if (item.href == "/app/upgrade")
                item.invisible = upgradeNotifyCount == 0;
        }
        return item;
    });
    // useEffect(() => {
    //     if (openMobile && onMobileClose) {
    //         onMobileClose();
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [location.pathname]);
    const myRoles = [];
    user?.roles.map(({id, name}) => {
        myRoles.push(id);
    });
    const content = (
        <Box height="100%" display="flex" flexDirection="column">
            <RouterLink to="/" style={{margin: 12, marginLeft: 48}}>
                <Logo />
            </RouterLink>
            <Box
                alignItems="center"
                display="flex"
                flexDirection="column"
                p={2}
            >
                <Avatar
                    className={classes.avatar}
                    // component={RouterLink}
                    src={user?.picture}
                    to="/app/account"
                />
                <Typography
                    className={classes.name}
                    color="textPrimary"
                    variant="h5"
                >
                    {user?.name || `-`}
                </Typography>
                <Typography color="textSecondary" variant="body2" style={{width: 168}}>
                    {user?.roles.map(({name}) => name).join(",")}
                </Typography>
            </Box>
            <Divider/>
            <Box
                p={2}
                alignItems="center"
                display="flex"
                flexDirection="column"
            >
                {formatedItems.map((item) => {
                    if (item.roles) {
                        var found = false;
                        item.roles.map((role) => {
                            if (myRoles.indexOf(role) != -1) {
                                found = true
                                return;
                            }
                        })
                        if (!found) {
                            return;
                        }
                    }
                    return (
                            <NavItem
                                style={{width: 120}}
                                href={item.href}
                                title={item.title}
                                icon={item.icon}
                                badge={item.invisible}
                            />
                    );
                })}
            </Box>
        </Box>
    );

    return (
        <>
            {/*<Hidden xlUp>*/}
            {/*    <Drawer*/}
            {/*        anchor="left"*/}
            {/*        classes={{paper: classes.mobileDrawer}}*/}
            {/*        onClose={onMobileClose}*/}
            {/*        open={openMobile}*/}
            {/*        variant="temporary"*/}
            {/*    >*/}
            {/*        {content}*/}
            {/*    </Drawer>*/}
            {/*</Hidden>*/}
            {/*<Hidden mdDown>*/}
                <Drawer
                    anchor="left"
                    style={{width: openDrawer ? 200 : 0, height: "calc(100%)", zIndex: 10}}
                    open={openDrawer}
                    variant="persistent"
                >
                    {content}
                </Drawer>
            <IconButton
                style={{position: "absolute", top: "0px", left: "0px", zIndex: 15}}
                onClick={()=>setOpenDrawer(!openDrawer)}
            >
                <Menu/>
            </IconButton>
            {/*</Hidden>*/}
        </>
    );
};

NavBar.propTypes = {
    openDrawer: PropTypes.bool,
};

NavBar.defaultProps = {
    openDrawer: true,
};

export default NavBar;
