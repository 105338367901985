import React, { useState } from "react";
import { previewPdf, saveFile } from "../../common/Util";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    MenuItem,
    Select,
    TextField,
} from "@material-ui/core";
import { map } from "lodash";
import { Order } from "../../generated/graphql";
import { NumberFormatCustom } from "../../common/FormRenderer";
import {addDays, format} from "date-fns";

const datePart = () => {
    const date = new Date();
    return `${(date.getMonth() + 1).toString().padStart(2, "0")}${date
        .getDate()
        .toString()
        .padStart(2, "0")}${date.getFullYear()}`;
};
const getPdf = async (order: Order, type, taxAmount, title, quoteExpiry) => {
    saveFile(
        `/orders/orderContract/${order.id}?type=${type}&taxAmount=${taxAmount}&quoteExpiry=${quoteExpiry}`,
        `${title}_${order.name}_${datePart()}.pdf`
    );
};

export enum OrderContractType {
    easylive = "easylive",
    standard = "standard",
    koolive = "koolive",
}
const OrderContractMap = {
    standard: "數位廣告合約",
    easylive: "直播銷售委刊合約",
    koolive: "koo.live合約",
};

export default ({ order }) => {
    const [open, setOpen] = useState(false);
    const [type, setType] = useState(OrderContractType.standard);
    let amount = 0;
    order.items?.map((orderItem) => {
        amount += orderItem.totalAmount + orderItem.serviceFee;
    });
    const [taxAmount, setTaxAmount] = useState(Math.round(amount * 0.05));
    const [quoteExpiry, setQuoteExpiry] = useState(format(addDays(new Date(), 14), "yyyy-MM-dd"));
    return (
        <div>
            <Dialog open={open}>
                <DialogContent
                    style={{ justifyContent: "center", display: "grid" }}
                >

                    <TextField
                        label="購買金額"
                        value={amount}
                        InputProps={{
                            inputComponent: NumberFormatCustom as any,
                        }}
                        disabled
                    />
                    <TextField
                        label="稅金調整"
                        value={taxAmount}
                        onChange={({ target }) => {
                            setTaxAmount(parseInt(target.value));
                        }}
                        InputProps={{
                            inputComponent: NumberFormatCustom as any,
                        }}
                    />

                    <TextField
                        label="總金額調整"
                        value={amount+taxAmount}
                        onChange={({ target }) => {
                            setTaxAmount(parseInt(target.value)-amount);
                        }}
                        InputProps={{
                            inputComponent: NumberFormatCustom as any,
                        }}
                    />
                    <Select
                        value={type}
                        onChange={(e) =>
                            setType(e.target.value as OrderContractType)
                        }
                    >
                        {map(OrderContractType, (v) => (
                            <MenuItem key={v} value={v}>
                                {OrderContractMap[v]}
                            </MenuItem>
                        ))}
                    </Select>
                    <TextField
                        label="報價有效期限"
                        type="date"
                        value={quoteExpiry}
                        onChange={(e) => setQuoteExpiry(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </DialogContent>
                <DialogActions
                    style={{ justifyContent: "center", display: "flex" }}
                >
                    <Button
                        variant={"contained"}
                        color={"primary"}
                        onClick={() => {
                            getPdf(order, type, taxAmount, "專案付款單", quoteExpiry);
                        }}
                        style={{ height: 40, width: 30 }}
                    >
                        下載
                    </Button>
                    <Button
                        variant={"contained"}
                        color={"secondary"}
                        onClick={() => {
                            previewPdf(
                                `/orders/orderContract/${order?.id}?type=${type}&taxAmount=${taxAmount}&quoteExpiry=${quoteExpiry}`,
                                `order-${order?.number}.pdf`
                            );
                        }}
                    >
                        預覽
                    </Button>
                    <Button
                        variant={"contained"}
                        color={"secondary"}
                        onClick={() => {
                            setOpen(false);
                            setTaxAmount(Math.round(amount * 0.05));
                            setQuoteExpiry(format(addDays(new Date(), 14), "yyyy-MM-dd"));
                        }}
                    >
                        取消
                    </Button>
                </DialogActions>
            </Dialog>
            <Button
                variant={"contained"}
                color={"primary"}
                onClick={() => setOpen(true)}
            >
                下載委刊單
            </Button>
        </div>
    );
};
